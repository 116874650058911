<template>
    <!-- 拍照主题组件 -->
    <div class="phototheme_box">
        <!-- 标题 -->
        <div class="phototheme-title">PHOTOGRAPHIC THEME</div>
        <!-- 副标题 -->
        <div class="phototheme-subtitle">拍摄主题</div>
        <photograph-theme-item
            :theme-data="photograph_theme_campus">
            <template v-slot:title>
                <div class="phototheme-item-title">校园主题 / Campus Photography</div>
            </template>
        </photograph-theme-item>
        <photograph-theme-item
            :theme-data="photograph_theme_personal">
            <template v-slot:title>
                <div class="phototheme-item-title">个人及家庭主题 / Family Photography</div>
            </template>
        </photograph-theme-item>
        <photograph-theme-item
            :theme-data="photograph_theme_biz">
            <template v-slot:title>
                <div class="phototheme-item-title">商业主题 / Commercial Photography</div>
            </template>
        </photograph-theme-item>
        <photograph-theme-item
            :theme-data="photograph_theme_video">
            <template v-slot:title>
                <div class="phototheme-item-title">视频服务 / Video Services</div>
            </template>
        </photograph-theme-item>
        <photograph-theme-item
            :theme-data="photograph_theme_classical">
            <template v-slot:title>
                <div class="phototheme-item-title">传统工艺照相馆 / Classic Photography</div>
            </template>
        </photograph-theme-item>
    </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
    data(){
        return {
        }
    },
    computed: mapState({
        photographTheme: state => state.goodsList.photograph_theme,
        photograph_theme_campus: state => state.goodsList.photograph_theme_campus,
        photograph_theme_personal: state => state.goodsList.photograph_theme_personal,
        photograph_theme_biz: state => state.goodsList.photograph_theme_biz,
        photograph_theme_video: state => state.goodsList.photograph_theme_video,
        photograph_theme_classical: state => state.goodsList.photograph_theme_classical,
    }),
    components: {
        photographThemeItem: () => import("@/components/photographThemeItem")
    }
}
</script>

<style scoped>

/* 拍照主题盒子 */
.phototheme_box{
    margin-top: 70px;
    text-align: center;
}
/* 标题 */
.phototheme-title{
    font-size: 40px;
    font-weight: bold;
}
/* 副标题 */
.phototheme-subtitle{
    font-size: 30px;
    margin-bottom: 20px;
}
/* 主题小标题 */
.phototheme-item-title{
    text-align: center;
    padding: 20px 10px;
    font-size: 20px;
    color: #303133;
}
</style>
